import { render, staticRenderFns } from "./ManageteamSection.vue?vue&type=template&id=36b2ff39&scoped=true"
import script from "./ManageteamSection.vue?vue&type=script&lang=js"
export * from "./ManageteamSection.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36b2ff39",
  null
  
)

export default component.exports